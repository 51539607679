import styled from "@emotion/styled";

import { HeaderMenu } from "./header-menu";

export const StyledHeaderMenu = styled(HeaderMenu)`
  display: none;

  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;
  }
`;
