import type { FunctionComponent } from "react";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import {
  Container,
  LogoContainer,
  LogoLink,
  StyledHeaderActions,
  StyledLogoPosties,
  Wrapper,
} from "scmp-app/components/posties/header/common/styles";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { page as PostiesPage } from "scmp-app/pages/posties";

import { StyledHeaderMenu } from "./styles";

export type Props = {
  className?: string;
};

export const PostiesContentHeader: FunctionComponent<Props> = ({ className }) => {
  const { pathname, query } = PostiesPage.route({});

  const currentPageType = useCurrentPageType();
  const postiesHeaderQueryParameters = {
    module: tracking.module.HeaderPosties,
    pgtype: currentPageType,
  };

  return (
    <Container className={className}>
      <Wrapper>
        <LogoContainer>
          <LogoLink
            as="/posties"
            pathname={pathname}
            query={{ ...query, ...postiesHeaderQueryParameters }}
          >
            <StyledLogoPosties />
          </LogoLink>
        </LogoContainer>
        <ClientSideSuspense>
          <StyledHeaderMenu />
        </ClientSideSuspense>
        <StyledHeaderActions />
      </Wrapper>
    </Container>
  );
};

PostiesContentHeader.displayName = "PostiesContentHeader";
