import type { FunctionComponent } from "react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { page as postiesKidsPage } from "scmp-app/pages/posties/kids";

import { useMenuData } from "./hooks";
import { Container, StyledHeaderMenuItem, StyledIconHome } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderMenu: FunctionComponent<Props> = ({ className }) => {
  const { menuData } = useMenuData();

  const { pathname, query } = postiesKidsPage.route({});

  const currentPageType = useCurrentPageType();
  const postiesHeaderQueryParameters = {
    module: tracking.module.HeaderPosties,
    pgtype: currentPageType,
  };

  return (
    <Container className={className}>
      <BaseLink pathname={pathname} query={{ ...query, ...postiesHeaderQueryParameters }}>
        <StyledIconHome />
      </BaseLink>
      {menuData.map((item, index) => (
        <StyledHeaderMenuItem
          key={index}
          name={item.name}
          pathname={item.urlAlias}
          query={item.query}
          subMenuLinkProps={item.subMenuLinkProps?.map(item => ({
            name: item.name,
            pathname: item.urlAlias,
            query: item.query,
          }))}
        />
      ))}
    </Container>
  );
};

HeaderMenu.displayName = "HeaderMenu";
