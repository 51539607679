import styled from "@emotion/styled";

import { HeaderMenuItem } from "scmp-app/components/posties/header/common/header-menu-item";
import { MenuItemName } from "scmp-app/components/posties/header/common/header-menu-item/styles";

import IconHome from "./icon-home.svg";

export const StyledHeaderMenuItem = styled(HeaderMenuItem)`
  ${MenuItemName} {
    line-height: 28px;
  }
`;

export const StyledIconHome = styled(IconHome)`
  &:hover {
    rect {
      fill: #fecc17;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
